import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import QuestionCard from "./QuestionCard";
import GameIntroduction from "./GameIntroduction";
import GameSetup from "./GameSetup";

function App() {
  const [players, setPlayers] = useState([]);
  const [newPlayer, setNewPlayer] = useState("");
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [isGroupActivity, setIsGroupActivity] = useState(false);

  const [commonQuestionsEnabled, setCommonQuestionsEnabled] = useState(true);
  const [groupQuestionsEnabled, setGroupQuestionsEnabled] = useState(true);
  const [questionsSixteenEnabled, setQuestionsSixteenEnabled] = useState(false);
  const [questionsEighteenEnabled, setQuestionsEighteenEnabled] =
    useState(false);

  const [questions] = useState([
    "Stelle der Person rechts von dir eine Ja/Nein-Frage. Wenn sie lügt und jemand es beweisen kann, muss sie trinken.",
    "Rufe eine zufällige Nummer an und versuche, die Person am anderen Ende zum Lachen zu bringen. Wenn es nicht klappt, trinkst du.",
    "Wähle einen Spieler aus, der für die nächsten 10 Minuten sprechen muss, als wäre er/sie ein Pirat. Lehnt der Spieler ab, muss er trinken.",
    "Führe eine 30-sekündige Comedy-Einlage vor. Wenn die Gruppe nicht lacht, musst du trinken.",
    "Tausche für zwei Runden dein Getränk mit dem Spieler deiner Wahl. Lehnt der Spieler ab, muss er trinken.",
    "Wähle einen Spieler, der für die nächsten zwei Runden nur in Reimen sprechen darf. Versagt er/sie, muss er/sie trinken.",
    "Wähle zwei Spieler aus, die Armdrücken machen müssen. Der Verlierer trinkt.",
    "Wähle einen Spieler aus, der eine Runde lang mit den Händen hinter dem Rücken spielen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, ein Tier nachzuahmen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der ein Geheimnis über sich verraten muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Führe eine kurze Yoga-Übung vor. Wenn die Gruppe sie nicht nachmachen kann, müssen sie trinken.",
    "Fordere einen Spieler auf, einen Akzent nachzumachen. Lehnt er/sie ab oder macht es schlecht, muss er/sie trinken.",
    "Wähle einen Spieler aus, der die Nationalhymne summen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, einen Zungenbrecher zu sagen. Wenn er/sie scheitert, muss er/sie trinken.",
    "Wähle einen Spieler, der einen Tanz aus einem berühmten Musikvideo nachmachen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, einen Handstand zu versuchen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle jemanden, der für die nächsten drei Runden jedes Mal trinken muss, wenn jemand anderes trinkt.",
    "Fordere einen Spieler auf, ein Lied zu pfeifen. Wenn die Gruppe das Lied nicht erraten kann, muss der Spieler trinken.",
    "Wähle einen Spieler, der die nächste Runde im Sitzen auf dem Boden verbringen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, einen Witz zu erzählen. Wenn niemand lacht, muss er/sie trinken.",
    "Wähle einen Spieler, der in den nächsten 2 Minuten eine Geschichte ausdenken muss. Wenn die Gruppe nicht überzeugt ist, muss er/sie trinken.",
    "Fordere einen Spieler auf, ein Geräusch eines Tieres nachzumachen. Lehnt er/sie ab, muss er trinken.",
    "Wähle einen Spieler, der für die nächste Runde sein Getränk mit einem anderen Spieler tauschen muss. Lehnt er/sie ab, muss er trinken.",
    "Fordere einen Spieler auf, einen Zungenbrecher dreimal schnell zu wiederholen. Scheitert er/sie, muss er/sie trinken.",
    "Wähle einen Spieler, der dir ein Geheimnis erzählen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, einen bekannten Tanzschritt nachzumachen. Lehnt er/sie ab, muss er trinken.",
    "Wähle jemanden, der eine Runde lang nur Fragen stellen darf. Lehnt er/sie ab oder macht einen Fehler, muss er/sie trinken.",
    "Fordere einen Spieler auf, ein Geräusch von einem Vogel zu imitieren. Lehnt er/sie ab, muss er trinken.",
    "Wähle einen Spieler, der eine Runde lang mit verbundenen Augen spielen muss. Lehnt er/sie ab, muss er trinken.",
    "Fordere einen Spieler auf, einen bekannten Werbeslogan nachzuahmen. Wenn die Gruppe ihn nicht erkennt, muss der Spieler trinken.",
    "Wähle einen Spieler, der die Farbe seiner/ihrer Socken beschreiben muss. Wenn er/sie falsch liegt, muss er/sie trinken.",
    "Fordere einen Spieler auf, ein Märchen zu erzählen. Wenn die Gruppe nicht zufrieden ist, muss er/sie trinken.",
    "Wähle einen Spieler, der für die nächste Runde mit der linken Hand trinken muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, eine bekannte Melodie zu summen. Wenn die Gruppe sie nicht erkennt, muss er/sie trinken.",
    "Wähle jemanden, der für die nächste Runde nur auf Fragen antworten darf. Lehnt er/sie ab, muss er trinken.",
    "Fordere einen Spieler auf, ein berühmtes Zitat zu sagen. Wenn niemand das Zitat erkennt, muss er trinken.",
    "Wähle einen Spieler, der für die nächste Runde nur flüstern darf. Lehnt er/sie ab, muss er trinken.",
    "Fordere einen Spieler auf, eine Figur aus einem Kinderbuch nachzumachen. Lehnt er/sie ab, muss er trinken.",
    "Wähle jemanden, der für die nächste Runde keinen einzigen Schluck trinken darf. Lehnt er/sie ab, muss er trinken.",
    "Fordere einen Spieler auf, einen bekannten Tanzstil nachzumachen. Lehnt er/sie ab, muss er trinken.",
    "Wähle jemanden, der für die nächsten drei Runden jedes Mal zwei Schlucke trinken muss, wenn er trinkt.",
    "Fordere einen Spieler auf, ein Gedicht aufzusagen. Lehnt er/sie ab, muss er trinken.",
    "Wähle einen Spieler, der für die nächste Runde seine Hände nicht benutzen darf. Lehnt er/sie ab, muss er trinken.",
    "Fordere einen Spieler auf, ein Lied rückwärts zu singen. Wenn er/sie scheitert, muss er/sie trinken.",
    "Wähle jemanden, der für die nächste Runde ohne Worte kommunizieren muss. Lehnt er/sie ab, muss er trinken.",
    "Fordere einen Spieler auf, ein Geheimnis über sich preiszugeben. Lehnt er/sie ab, muss er trinken.",
    "Wähle jemanden, der für die nächste Runde nur flüstern darf. Lehnt er/sie ab, muss er trinken.",
    "Fordere einen Spieler auf, eine berühmte Persönlichkeit nachzuahmen. Wenn niemand sie errät, muss der Spieler trinken.",
    "Wähle jemanden, der für die nächsten zwei Runden jede Regel brechen darf. Lehnt er/sie ab, muss er trinken.",
    "Fordere einen Spieler auf, eine Runde lang nur in Rätseln zu sprechen. Lehnt er/sie ab, muss er trinken.",
    "Wähle jemanden, der für die nächste Runde mit den Augen verbunden spielen muss. Lehnt er/sie ab, muss er trinken.",
    "Fordere einen Spieler auf, die Nationalhymne zu summen. Lehnt er/sie ab, muss er trinken.",
    "Wähle jemanden, der für die nächste Runde nur Ja und Nein antworten darf. Lehnt er/sie ab, muss er trinken.",
    "Fordere einen Spieler auf, einen Satz in einer anderen Sprache zu sagen. Lehnt er/sie ab, muss er trinken.",
    "Wähle jemanden, der für die nächste Runde sein Getränk mit einem anderen Spieler tauschen muss. Lehnt er/sie ab, muss er trinken.",
    "Fordere einen Spieler auf, ein bekanntes Zitat zu sagen. Wenn niemand es erkennt, muss der Spieler trinken.",
    "Wähle jemanden, der für die nächste Runde keine Fragen beantworten darf. Lehnt er/sie ab, muss er trinken.",
    "Fordere einen Spieler auf, einen Prominenten nachzumachen. Lehnt er/sie ab, muss er trinken.",
  ]);

  const [questionsSixteen] = useState([
    "Wähle einen Spieler, der dir eine Wahrheit oder Pflicht-Frage stellen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, ein peinliches Erlebnis zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der für die nächste Runde in Zeitlupe sprechen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre letzte Textnachricht vorzulesen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der ein Kompliment an eine Person im Raum machen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, jemanden im Raum zu umarmen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der einen kurzen Sketch mit einem anderen Spieler nachstellen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, den peinlichsten Song in seiner/ihrer Playlist zu nennen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der sein/e schlimmste/r Anmachspruch vorführen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, für 30 Sekunden zu tanzen, ohne Musik. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre Social Media-Profile zeigen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre schlechteste Tanzbewegung zu zeigen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der einen fiktiven Liebesbrief an jemanden im Raum schreiben muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, ein schräges Geräusch zu machen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der für die nächste Runde nur mit einem Akzent sprechen darf. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre geheime Schwäche zu offenbaren. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der für die nächste Runde jemand anderem im Raum Komplimente machen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre letzte Google-Suche preiszugeben. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der für die nächste Runde ein schräges Wort in jedem Satz einbauen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, eine geheime Fähigkeit zu zeigen. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, eine peinliche Geschichte aus seiner/ihrer Schulzeit zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der ein lustiges Foto von sich in der Gruppe zeigen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre beste schauspielerische Leistung vorzuführen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der die witzigste Nachricht, die er/sie je erhalten hat, vorlesen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, eine Minute lang eine absurde Sportart nachzuahmen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der dir sein/ihr Lieblingsmeme zeigen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre aktuelle Lieblingsserie zu erklären. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der eine verrückte Theorie über etwas im Raum entwickeln muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, ein Karaoke-Lied vorzutragen, ohne die Musik. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre peinlichste Angewohnheit offenbaren muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, für 20 Sekunden einen berühmten Filmcharakter nachzuahmen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre letzte schlechte Entscheidung erklären muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, die schlechteste Ausrede, die er/sie jemals verwendet hat, zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der für die nächste Runde nur mit übertriebener Gestik sprechen darf. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre Lieblingsbeschäftigung in einem Satz zu beschreiben. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre größte Angst preisgeben muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, für 30 Sekunden als Nachrichtensprecher zu agieren. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre letzte WhatsApp-Nachricht vorlesen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, einen Zungenbrecher mit vollem Mund zu sagen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der sein/ihr absurdestes Missverständnis erzählt. Lehnt er/sie ab, muss er/sie trinken.",
  ]);

  const [questionsEighteen] = useState([
    "Fordere einen Spieler auf, seine/ihre wildeste Nacht zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der verraten muss, ob er/sie jemals nackt geschwommen ist. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, das verrückteste, was er/sie je in einem Club gemacht hat, zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre schlimmste Dating-Erfahrung offenbaren muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu verraten, ob er/sie jemals einen One-Night-Stand hatte. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der gestehen muss, ob er/sie jemals eine Nacht durchgemacht hat, ohne zu schlafen. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu erzählen, ob er/sie jemals betrunken war und es am nächsten Tag bereut hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seinen/ihren peinlichsten Moment beim Küssen beschreiben muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu verraten, ob er/sie jemals jemandem einen anzüglichen Text geschickt hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre wildeste Fantasie verraten muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu erzählen, ob er/sie jemals etwas gestohlen hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seinen/ihren größten Alkohol-Blackout erzählen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu gestehen, ob er/sie jemals betrunken gefahren ist. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre Erfahrungen mit Online-Dating beschreiben muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, sein/ihr peinlichstes Sexting-Erlebnis zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre schlimmste sexuelle Erfahrung teilen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu verraten, ob er/sie jemals Sex an einem öffentlichen Ort hatte. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der gestehen muss, ob er/sie jemals jemanden betrogen hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre schlimmste Kater-Geschichte zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der zugeben muss, ob er/sie jemals nackt in der Natur war. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu verraten, ob er/sie jemals einen Strip-Club besucht hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre heftigste Party-Erfahrung teilen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre unangenehmste sexuelle Begegnung zu beschreiben. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der zugeben muss, ob er/sie jemals gefilmt wurde, während er/sie etwas Intimes tat. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu erzählen, ob er/sie jemals eine erotische Massage hatte. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der verraten muss, ob er/sie jemals betrunken in einem Taxi eingeschlafen ist. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, die schrägste Lüge, die er/sie jemals jemandem gesagt hat, zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der zugeben muss, ob er/sie jemals jemanden verführt hat, nur um eine Wette zu gewinnen. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu verraten, ob er/sie jemals nackt fotografiert wurde. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre schlimmste Erfahrung mit einer unerwarteten Überraschung teilen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, die peinlichste Nachricht, die er/sie jemals gesendet hat, vorzulesen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der erzählen muss, ob er/sie jemals mit jemandem auf einer Party rumgemacht hat, ohne den Namen zu kennen. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zuzugeben, ob er/sie jemals etwas an jemandem bereut hat, während er/sie betrunken war. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre wildeste Fantasie bezüglich eines Prominenten preisgeben muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu erzählen, ob er/sie jemals eine peinliche Situation in einem Hotelzimmer hatte. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der verraten muss, ob er/sie jemals jemanden geküsst hat, den er/sie nicht küssen sollte. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu gestehen, ob er/sie jemals absichtlich jemanden nackt gesehen hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre schmutzigste Fantasie erzählen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, die schlimmste Lüge zu erzählen, die er/sie jemals in einer Beziehung gesagt hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der zugeben muss, ob er/sie jemals in einem verrückten Kostüm aufgetreten ist. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu gestehen, ob er/sie jemals in einer unangemessenen Situation aufgewacht ist. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre frechste Nachricht zeigen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre schlimmste Situation mit einem betrunkenen Freund zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der zugeben muss, ob er/sie jemals etwas Verbotenes in der Öffentlichkeit gemacht hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu verraten, ob er/sie jemals erwischt wurde, als er/sie etwas Verbotenes tat. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre peinlichste Erfahrung beim Flirten teilen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu erzählen, ob er/sie jemals mit jemandem auf einer Party eingeschlafen ist. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der zugeben muss, ob er/sie jemals in einer Notsituation völlig überfordert war. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu verraten, ob er/sie jemals einen Dreier in Erwägung gezogen hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre wildeste Fantasie teilen muss, die er/sie noch nie jemandem erzählt hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre Erfahrungen mit Sextoys zu beschreiben. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der gestehen muss, ob er/sie jemals bei einem One-Night-Stand übernachtet hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu erzählen, ob er/sie jemals mit jemandem geschlafen hat, den er/sie bereut. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre wildeste Partyerfahrung im Ausland erzählen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zuzugeben, ob er/sie jemals beim Sex erwischt wurde. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre schlimmste Erfahrung mit einem Kater teilen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu erzählen, ob er/sie jemals jemanden betrogen hat, während er/sie in einer Beziehung war. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der zugeben muss, ob er/sie jemals Nacktbilder verschickt hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre verrückteste Nacht mit Drogen oder Alkohol zu beschreiben. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der gestehen muss, ob er/sie jemals ein verbotenes Abenteuer auf Reisen hatte. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu erzählen, ob er/sie jemals in einem Stripclub war. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre wildeste Nacht in einem Hotelzimmer erzählen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zuzugeben, ob er/sie jemals Sex im Auto hatte. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der verraten muss, ob er/sie jemals jemanden verführt hat, nur um einen Drink zu bekommen. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre schlimmste Erfahrung mit einem One-Night-Stand zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der gestehen muss, ob er/sie jemals eine verbotene Affäre hatte. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu erzählen, ob er/sie jemals Sex an einem öffentlichen Ort hatte. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre peinlichste sexuelle Erfahrung teilen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu verraten, ob er/sie jemals jemanden verführt hat, den er/sie nicht kannte. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre geheimste Fantasie über einen Freund/Freundin im Raum erzählen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu erzählen, ob er/sie jemals etwas Intimes mit einer berühmten Persönlichkeit in Gedanken getan hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der zugeben muss, ob er/sie jemals an einem verbotenen Ort erwischt wurde. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre schlimmste Erfahrung mit einer unerwarteten sexuellen Begegnung zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der verraten muss, ob er/sie jemals jemanden nur für Sex getroffen hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre wildeste Nacht mit Alkohol oder Drogen zu beschreiben. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der zugeben muss, ob er/sie jemals jemanden wegen seines/ihres Körpers verführt hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu erzählen, ob er/sie jemals nackt oder halbnackt vor Fremden stand. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der verraten muss, ob er/sie jemals für ein erotisches Shooting posiert hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zuzugeben, ob er/sie jemals über eine offene Beziehung nachgedacht hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre schrägste Erfahrung in einem Swingerclub teilen muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu erzählen, ob er/sie jemals einen romantischen Partner im Ausland hatte. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der verraten muss, ob er/sie jemals eine erotische Geschichte geschrieben hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre schlimmste Erfahrung mit einer Dating-App zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der zugeben muss, ob er/sie jemals einen sexuellen Fetisch ausprobiert hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre verrückteste Geschichte von einer Party im Ausland zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der verraten muss, ob er/sie jemals jemanden verführt hat, um in einen exklusiven Club zu kommen. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu erzählen, ob er/sie jemals für Sex bezahlt hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre wildeste Nacht in einer fremden Stadt beschreiben muss. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zuzugeben, ob er/sie jemals jemanden zum Sex überredet hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der verraten muss, ob er/sie jemals eine erotische Fantasie über jemanden im Raum hatte. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre peinlichste Geschichte von einem missglückten Flirt zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der zugeben muss, ob er/sie jemals einen sexuellen Traum über jemanden im Raum hatte. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, seine/ihre wildeste Erfahrung in einem anderen Land zu erzählen. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der verraten muss, ob er/sie jemals jemanden in einer Bar aufgerissen hat. Lehnt er/sie ab, muss er/sie trinken.",
    "Fordere einen Spieler auf, zu erzählen, ob er/sie jemals in eine unangenehme Situation mit einem Ex verwickelt war. Lehnt er/sie ab, muss er/sie trinken.",
    "Wähle einen Spieler, der seine/ihre geheimste Fantasie über jemanden im Raum teilen muss. Lehnt er/sie ab, muss er/sie trinken.",
  ]);

  const [groupQuestions] = useState([
    "Alle, die schon einmal betrunken einen Ex kontaktiert haben, müssen trinken.",
    "Jeder, der schon einmal Karaoke gesungen hat, muss trinken.",
    "Alle, die jemals eine schlechte Frisur hatten, müssen trinken.",
    "Jeder, der schon mal bei einer Sportveranstaltung geweint hat, muss trinken.",
    "Alle, die schon einmal in der Öffentlichkeit getanzt haben, müssen trinken.",
    "Jeder, der in den letzten 24 Stunden geflirtet hat, muss trinken.",
    "Jeder, der sich schon einmal die Haare gefärbt hat, muss trinken.",
    "Jeder, der in den letzten 7 Tagen Sport gemacht hat, darf einen Schluck trinken.",
    "Jeder, der noch nie eine Rede gehalten hat, muss trinken.",
    "Alle, die mehr als einen Social-Media-Account haben, müssen trinken.",
    "Jeder, der heute schon etwas Süßes gegessen hat, muss trinken.",
    "Alle, die schon einmal ein Instrument gespielt haben, müssen trinken.",
    "Jeder, der jemals ein Online-Dating-Profil erstellt hat, muss trinken.",
    "Jeder, der schon einmal auf einem Konzert war, muss trinken.",
    "Alle, die jemals durch eine Zeitumstellung verwirrt wurden, müssen trinken.",
    "Jeder, der schon einmal vergessen hat, wo er sein Auto geparkt hat, muss trinken.",
    "Jeder, der in einem anderen Land geboren wurde, muss trinken.",
    "Alle, die jemals eine Nacht durchgefeiert haben, müssen trinken.",
    "Jeder, der einen Führerschein hat, muss trinken.",
    "Alle, die heute Kaffee getrunken haben, müssen trinken.",
    "Jeder, der schon einmal bei einem Horrorfilm Angst hatte, muss trinken.",
    "Alle, die mehr als drei Stunden Schlaf letzte Nacht hatten, müssen trinken.",
    "Jeder, der jemals eine Rede gehalten hat, muss trinken.",
    "Alle, die in den letzten 24 Stunden auf Social Media aktiv waren, müssen trinken.",
    "Jeder, der jemals ein Selfie gemacht hat, muss trinken.",
    "Alle, die jemals in einem anderen Land Urlaub gemacht haben, müssen trinken.",
    "Jeder, der jemals einen Prominenten getroffen hat, muss trinken.",
    "Alle, die heute geduscht haben, müssen trinken.",
    "Jeder, der jemals einen Film zweimal im Kino gesehen hat, muss trinken.",
    "Alle, die in den letzten sieben Tagen Fast Food gegessen haben, müssen trinken.",
    "Jeder, der jemals eine öffentliche Rede gehalten hat, muss trinken.",
    "Jeder, der jemals einen Lehrer gemocht hat, muss trinken.",
    "Alle, die jemals ein Musikinstrument gelernt haben, müssen trinken.",
    "Jeder, der schon einmal in einem Pool geschwommen ist, muss trinken.",
    "Alle, die heute ein Kleidungsstück in ihrer Lieblingsfarbe tragen, müssen trinken.",
    "Jeder, der jemals in einem Flugzeug geflogen ist, muss trinken.",
    "Jeder, der jemals eine Fremdsprache gelernt hat, muss trinken.",
    "Jeder, der heute Milch getrunken hat, muss trinken.",
    "Jeder, der jemals einen Baum geklettert ist, muss trinken.",
    "Jeder, der jemals in einem Club getanzt hat, muss trinken.",
    "Alle, die jemals eine Strafe bekommen haben, müssen trinken.",
    "Jeder, der jemals auf einer Hochzeit war, muss trinken.",
    "Jeder, der jemals ein Haustier hatte, muss trinken.",
    "Alle, die heute eine Tasse Kaffee getrunken haben, müssen trinken.",
    "Jeder, der jemals ein Videospiel gespielt hat, muss trinken.",
    "Alle, die heute ein Getränk mit Kohlensäure getrunken haben, müssen trinken.",
    "Jeder, der jemals in einer Band gespielt hat, muss trinken.",
    "Jeder, der jemals eine Party geschmissen hat, muss trinken.",
    "Alle, die jemals ein Musical gesehen haben, müssen trinken.",
    "Jeder, der jemals in einem anderen Land gelebt hat, muss trinken.",
  ]);

  const addPlayer = () => {
    if (newPlayer.trim()) {
      setPlayers([...players, newPlayer]);
      setNewPlayer("");
    }
  };

  const deletePlayer = (index) => {
    setPlayers(players.filter((_, i) => i !== index));
  };

  const nextCard = () => {
    const groupActivityChance = Math.random() < 0.2;

    let availableQuestions = [];

    if (commonQuestionsEnabled) {
      availableQuestions = availableQuestions.concat(questions);
    }
    if (questionsSixteenEnabled) {
      availableQuestions = availableQuestions.concat(questionsSixteen);
    }
    if (questionsEighteenEnabled) {
      availableQuestions = availableQuestions.concat(questionsEighteen);
    }

    if (groupActivityChance && groupQuestionsEnabled) {
      const randomGroupQuestion =
        groupQuestions[Math.floor(Math.random() * groupQuestions.length)];
      setCurrentQuestion(randomGroupQuestion);
      setIsGroupActivity(true);
    } else {
      const randomQuestion =
        availableQuestions[
          Math.floor(Math.random() * availableQuestions.length)
        ];
      setCurrentQuestion(randomQuestion);
      setCurrentPlayerIndex((currentPlayerIndex + 1) % players.length);
      setIsGroupActivity(false);
    }
  };

  if (currentQuestion) {
    return (
      <QuestionCard
        onNext={nextCard}
        onEnd={() => setCurrentQuestion(null)}
        player={
          isGroupActivity
            ? "Gruppenaktivität"
            : players[
                currentPlayerIndex === 0
                  ? players.length - 1
                  : currentPlayerIndex - 1
              ]
        }
        question={currentQuestion}
      />
    );
  }

  return (
    <GameSetup
      newPlayer={newPlayer}
      setNewPlayer={setNewPlayer}
      players={players}
      addPlayer={addPlayer}
      deletePlayer={deletePlayer}
      commonQuestionsEnabled={commonQuestionsEnabled}
      setCommonQuestionsEnabled={setCommonQuestionsEnabled}
      groupQuestionsEnabled={groupQuestionsEnabled}
      setGroupQuestionsEnabled={setGroupQuestionsEnabled}
      questionsSixteenEnabled={questionsSixteenEnabled}
      setQuestionsSixteenEnabled={setQuestionsSixteenEnabled}
      questionsEighteenEnabled={questionsEighteenEnabled}
      setQuestionsEighteenEnabled={setQuestionsEighteenEnabled}
      nextCard={nextCard}
    />
  );
}

export default App;
