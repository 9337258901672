import React from "react";
import { Container, Typography, Box } from "@mui/material";

function GameIntroduction() {
  return (
    <Container sx={{ padding: "16px", maxWidth: "sm", textAlign: "center" }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Do Or Drink: Das ultimative Partyspiel für Mutige und Spaßvögel!
        </Typography>
        <Typography variant="subtitle1" component="h2" color="textSecondary">
          Fordere deine Freunde heraus mit schrägen Aufgaben und packenden
          Fragen – das perfekte Spiel für jede Runde, egal ob ihr zu zweit oder
          zu zehnt seid.
        </Typography>
      </Box>
    </Container>
  );
}

export default GameIntroduction;
