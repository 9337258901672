import React from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import GameIntroduction from "./GameIntroduction";

export default function GameSetup({
  newPlayer,
  setNewPlayer,
  players,
  addPlayer,
  deletePlayer,
  commonQuestionsEnabled,
  setCommonQuestionsEnabled,
  groupQuestionsEnabled,
  setGroupQuestionsEnabled,
  questionsSixteenEnabled,
  setQuestionsSixteenEnabled,
  questionsEighteenEnabled,
  setQuestionsEighteenEnabled,
  nextCard,
}) {
  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: "100vh",
        background: `radial-gradient(circle at 20% 20%, #ff6f61, #d32f2f)`, // Red background with gradient circles
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        paddingTop: "20px",
        overflowX: "hidden", // Prevent horizontal scrolling
      }}
    >
      <Container
        sx={{
          margin: "16px",
          padding: "16px",
          maxWidth: "sm",
          backgroundColor: "rgba(255, 255, 255, 0.8)", // Light background for content
          borderRadius: 3,
          boxShadow: "none", // No box shadow for cleaner look
        }}
      >
        <GameIntroduction />

        {/* Player Input */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "stretch", sm: "center" },
            gap: 2,
            mb: 2,
          }}
        >
          <TextField
            fullWidth
            label="Spielername"
            variant="outlined"
            value={newPlayer}
            onChange={(e) => setNewPlayer(e.target.value)}
          />
          <Button
            fullWidth={true}
            variant="contained"
            onClick={addPlayer}
            sx={{ mt: { xs: 2, sm: 0 }, width: { sm: "auto" } }}
          >
            Spieler hinzufügen
          </Button>
        </Box>

        {/* Player List */}
        <List>
          {players.map((player, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" onClick={() => deletePlayer(index)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={player} />
            </ListItem>
          ))}
        </List>

        {/* Game Options */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={commonQuestionsEnabled}
                onChange={(e) => setCommonQuestionsEnabled(e.target.checked)}
              />
            }
            label="Allgemeine Fragen"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={groupQuestionsEnabled}
                onChange={(e) => setGroupQuestionsEnabled(e.target.checked)}
              />
            }
            label="Gruppenfragen"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={questionsSixteenEnabled}
                onChange={(e) => setQuestionsSixteenEnabled(e.target.checked)}
              />
            }
            label="Fragen ab 16"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={questionsEighteenEnabled}
                onChange={(e) => setQuestionsEighteenEnabled(e.target.checked)}
              />
            }
            label="Fragen ab 18"
          />
        </Box>

        {/* Start Game Button */}
        <Button
          fullWidth
          disabled={players.length <= 1}
          variant="contained"
          color="primary"
          onClick={nextCard}
        >
          Spiel starten
        </Button>
      </Container>
    </Box>
  );
}
