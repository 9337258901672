import React from "react";
import { Card, CardContent, Typography, Box, Button } from "@mui/material";

export default function QuestionCard({ player, question, onNext, onEnd }) {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden", // Prevent scrolling
        background: `radial-gradient(circle at 20% 20%, #5a8dee, #1565c0)`, // Blue background with gradient circles
        position: "relative",
      }}
    >
      {/* Background Circles */}
      <Box
        sx={{
          position: "absolute",
          top: "10%",
          left: "15%",
          width: 200,
          height: 200,
          backgroundColor: "#0d47a1",
          borderRadius: "50%",
          opacity: 0.2,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "60%",
          right: "10%",
          width: 300,
          height: 300,
          backgroundColor: "#0d47a1",
          borderRadius: "50%",
          opacity: 0.2,
        }}
      />

      {/* Card Content */}
      <Card
        sx={{
          width: "100%",
          maxWidth: 400,
          padding: 3,
          borderRadius: 3,
          backgroundColor: "transparent", // No background, blending with the design
          boxShadow: "none", // Removed the box shadow
        }}
      >
        <CardContent sx={{ textAlign: "center", color: "#fff" }}>
          <Box sx={{ marginBottom: 2 }}>
            <Typography
              variant="h4"
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              {player}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 3 }}>
            <Typography variant="h5" component="div" sx={{ fontWeight: "500" }}>
              {question}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={onNext}
              sx={{
                minWidth: 140,
                padding: "10px 20px",
                fontSize: "1.2rem",
                backgroundColor: "#1e88e5",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            >
              Next
            </Button>
          </Box>
        </CardContent>
      </Card>
      {/* End Game Button */}
      <Button
        variant="outlined"
        onClick={onEnd}
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          fontSize: "0.8rem",
          padding: "5px 10px",
          color: "#fff",
          borderColor: "#fff",
          "&:hover": {
            borderColor: "#fff",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        }}
      >
        Spiel beenden
      </Button>
    </Box>
  );
}
